import React from 'react';
import './Dashboard.scss';
import Header from './components/Header';

function Dashboard() {
  return (
    <div className="Dashboard">
      <Header activePage="dashboard" />
      
      <main className="main-content">
        <h2>Dashboard</h2>
        <p>
          Welcome to your dashboard.
        </p>
      </main>
    </div>
  );
}

export default Dashboard;
