import { API_BASE_URL } from './config';

export const TOKEN_STORAGE_KEY = 'auth_token';

export const getToken = (): string | null => {
  return localStorage.getItem(TOKEN_STORAGE_KEY);
};

export const setToken = (token: string): void => {
  localStorage.setItem(TOKEN_STORAGE_KEY, token);
  
  window.dispatchEvent(new Event('auth-change'));
};

export const removeToken = (): void => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  
  window.dispatchEvent(new Event('auth-change'));
};

export const createHeaders = (additionalHeaders: Record<string, string> = {}): HeadersInit => {
  const headers: Record<string, string> = {
    ...additionalHeaders,
  };
  
  const token = getToken();
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  
  return headers;
};

export const apiRequest = async <T>(
  endpoint: string, 
  options: RequestInit = {}
): Promise<T> => {
  const url = `${API_BASE_URL}${endpoint}`;
  
  const headers = createHeaders({
    'Content-Type': 'application/json',
    ...(options.headers as Record<string, string> || {})
  });
  
  const config: RequestInit = {
    ...options,
    headers,
  };
  
  const response = await fetch(url, config);
  
  if (!response.ok) {
    let errorMessage = 'Request failed';
    try {
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
    } catch {
      errorMessage = await response.text() || errorMessage;
    }
    throw new Error(errorMessage);
  }
  
  if (response.status === 204) {
    return {} as T;
  }
  
  // Clone the response before reading it to avoid "body stream already read" errors
  const clonedResponse = response.clone();
  
  // Handle empty responses by returning an empty object instead of throwing an error
  const contentLength = response.headers.get('Content-Length');
  if (contentLength === '0' || contentLength === null) {
    try {
      const text = await clonedResponse.text();
      if (!text) {
        return {} as T;
      }
      // If there is text but contentLength was null/0, try to parse it as JSON
      return JSON.parse(text) as T;
    } catch (e) {
      // If parsing fails, return empty object
      return {} as T;
    }
  }
  
  try {
    return await response.json() as T;
  } catch (e) {
    // If JSON parsing fails, try to get text content
    const text = await clonedResponse.text();
    if (!text) {
      return {} as T;
    }
    throw new Error(`Failed to parse response as JSON: ${text}`);
  }
};
