import React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest, removeToken } from '../api';
import './Header.scss';
import { useUserCauses } from '../hooks/useUserCauses';
import { useUserAccounts } from '../hooks/useUserAccounts';

interface HeaderProps {
  showLogout?: boolean;
  activePage: 'dashboard' | 'causes' | 'accounts';
}

function Header({ showLogout = true, activePage }: HeaderProps) {
  const navigate = useNavigate();
  const { hasSelectedCauses, loading: causesLoading } = useUserCauses();
  const { hasLinkedAccounts, loading: accountsLoading } = useUserAccounts();
  
  // Determine if we're in onboarding mode
  const isOnboarding = !causesLoading && !accountsLoading && 
                      (hasSelectedCauses === false || hasLinkedAccounts === false);
  
  const shouldHideHeader = isOnboarding;
  
  if (shouldHideHeader) {
    return null;
  }

  const handleLogout = async () => {
    try {
      await apiRequest('/logout', {
        method: 'POST',
      });
      
      removeToken();
      
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <a href="/">
          <h1 className="logo-text">Free World Project</h1>
        </a>
      </div>
      <div className="nav-links">
        <a 
          href="/dashboard" 
          className={`nav-link ${activePage === 'dashboard' ? 'active' : ''}`}
        >
          Dashboard
        </a>
        <a 
          href="/causes" 
          className={`nav-link ${activePage === 'causes' ? 'active' : ''}`}
        >
          Causes
        </a>
        <a 
          href="/accounts" 
          className={`nav-link ${activePage === 'accounts' ? 'active' : ''}`}
        >
          Accounts
        </a>
      </div>
      {showLogout && (
        <div className="auth-buttons">
          <button 
            className="auth-button" 
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      )}
    </nav>
  );
}

export default Header;
