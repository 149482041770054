import { useState, useEffect } from 'react';
import { apiRequest } from '../api';

interface UseUserAccountsResult {
  hasLinkedAccounts: boolean | null;
  loading: boolean;
}

export const useUserAccounts = (): UseUserAccountsResult => {
  const [hasLinkedAccounts, setHasLinkedAccounts] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      
      try {
        const accounts = await apiRequest<any[]>('/accounts', {
          method: 'GET',
        });
        
        setHasLinkedAccounts(accounts.length > 0);
      } catch (error) {
        console.error('Error fetching user accounts:', error);
        setHasLinkedAccounts(null);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, []);

  return { hasLinkedAccounts, loading };
};
