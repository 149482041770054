import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from './api';
import Header from './components/Header';
import './Accounts.scss';
import './AddAccount.scss';
import { useUserCauses } from './hooks/useUserCauses';

// Twitter logo SVG component
const TwitterLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
  </svg>
);

interface SocialPlatform {
  id: string;
  name: string;
}

interface SocialAccount {
  id: string;
  userId: string;
  platform: string;
  username: string;
}

function AddAccount() {
  const navigate = useNavigate();
  const [platforms, setPlatforms] = useState<SocialPlatform[]>([]);
  const [accounts, setAccounts] = useState<SocialAccount[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);
  const [step, setStep] = useState<'select-platform' | 'twitter-option' | 'enter-username'>('select-platform');
  const [isNewAccount, setIsNewAccount] = useState<boolean | null>(null);
  const [username, setUsername] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Check if user has selected causes to determine if we're in onboarding mode
  const { hasSelectedCauses } = useUserCauses();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Fetch accounts and platforms in parallel
      const [accountsData, platformsData] = await Promise.all([
        apiRequest<SocialAccount[]>('/accounts', { method: 'GET' }),
        apiRequest<SocialPlatform[]>('/platforms', { method: 'GET' })
      ]);
      
      setAccounts(accountsData);
      setPlatforms(platformsData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load platforms. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handlePlatformSelect = (platformId: string) => {
    setSelectedPlatform(platformId);
    
    // If Twitter is selected, go to Twitter-specific flow
    if (platformId === 'twitter') {
      setStep('twitter-option');
    } else {
      // For other platforms, go directly to username entry
      setStep('enter-username');
    }
  };

  const handleTwitterOptionSelect = (isNew: boolean) => {
    setIsNewAccount(isNew);
    
    if (isNew) {
      // Navigate to account generator with platform info
      navigate('/accounts/generate', { 
        state: { 
          platform: selectedPlatform,
          platformName: platforms.find(p => p.id === selectedPlatform)?.name || 'Twitter'
        } 
      });
    } else {
      // For existing account, proceed to username entry
      setStep('enter-username');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!selectedPlatform || !username.trim()) {
      setError('Please enter a valid username.');
      return;
    }
    
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);
    
    try {
      await apiRequest<any>('/accounts', {
        method: 'POST',
        body: JSON.stringify({
          platform: selectedPlatform,
          username: username.trim()
        }),
      });
      
      setSuccessMessage('Account added successfully!');
      
      // Redirect to home after successful account addition
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } catch (error) {
      console.error('Error adding account:', error);
      setError('Failed to add account. Please try again later.');
      setIsSubmitting(false);
    }
  };

  const renderPlatformSelection = () => {
    if (loading) {
      return <div className="loading">Loading platforms...</div>;
    }

    return (
      <div className="platform-logos-container">
        <div className="platform-logos">
          {platforms.map(platform => (
            <div 
              key={platform.id} 
              className="platform-logo-item"
              onClick={() => handlePlatformSelect(platform.id)}
            >
              <div className="platform-logo">
                {platform.id === 'twitter' ? (
                  <div className="logo-placeholder twitter-logo">
                    <TwitterLogo />
                  </div>
                ) : (
                  <div className="logo-placeholder">{platform.name.charAt(0)}</div>
                )}
              </div>
              <span className="platform-name">{platform.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderTwitterOptions = () => {
    return (
      <div className="twitter-options-container">
        <h3>Twitter Account</h3>
        
        <div className="twitter-options">
          <button 
            className="twitter-option-button"
            onClick={() => handleTwitterOptionSelect(true)}
          >
            Create a new account
          </button>
          
          <button 
            className="twitter-option-button"
            onClick={() => handleTwitterOptionSelect(false)}
          >
            Connect existing account
          </button>
        </div>
        
        <button 
          className="back-button"
          onClick={() => setStep('select-platform')}
        >
          Back
        </button>
      </div>
    );
  };

  const renderUsernameEntry = () => {
    return (
      <div className="username-entry-container">
        <h3>Enter your {selectedPlatform === 'twitter' ? 'Twitter' : platforms.find(p => p.id === selectedPlatform)?.name} username</h3>
        
        <form onSubmit={handleSubmit} className="username-form">
          <div className="form-group">
            <label htmlFor="username-input">Username</label>
            <input
              id="username-input"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={isSubmitting}
              className="username-input"
              placeholder={`Enter your ${selectedPlatform === 'twitter' ? 'Twitter' : ''} username`}
              autoFocus
            />
          </div>
          
          <div className="form-buttons">
            <button 
              type="button" 
              className="back-button"
              onClick={() => {
                if (selectedPlatform === 'twitter') {
                  setStep('twitter-option');
                } else {
                  setStep('select-platform');
                }
              }}
              disabled={isSubmitting}
            >
              Back
            </button>
            
            <button 
              type="submit" 
              className="add-button" 
              disabled={isSubmitting || !username.trim()}
            >
              {isSubmitting ? (
                <>
                  <div className="spinner"></div>
                  <span>Linking account...</span>
                </>
              ) : 'Add Account'}
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="Accounts AddAccount">
      <Header activePage="accounts" showLogout={true} />
      
      <main className="main-content">
        <div className="accounts-header">
          <h2>Add Social Media Account</h2>
        </div>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        
        {successMessage && (
          <div className="success-message">
            {successMessage}
          </div>
        )}
        
        <div className="add-account-container">
          {step === 'select-platform' && renderPlatformSelection()}
          {step === 'twitter-option' && renderTwitterOptions()}
          {step === 'enter-username' && renderUsernameEntry()}
        </div>
      </main>
    </div>
  );
}

export default AddAccount;
