import React, { useEffect, useState, useRef } from 'react';
import { apiRequest } from './api';
import './Causes.scss';
import Header from './components/Header';
import { useNavigate } from 'react-router-dom';

interface Cause {
  id: string;
  name: string;
  description: string;
  selected?: boolean;
}

function Causes() {
  const navigate = useNavigate();
  const [causes, setCauses] = useState<Cause[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const isUpdatingRef = useRef(false);

  useEffect(() => {
    fetchCauses();
  }, []);

  const fetchCauses = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const causesData = await apiRequest<Cause[]>('/causes', {
        method: 'GET',
      });
      
      setCauses(causesData);
    } catch (error) {
      console.error('Error fetching causes:', error);
      setError('Failed to load causes. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleCauseToggle = (id: string) => {
    // Prevent duplicate calls during state updates
    if (isUpdatingRef.current) {
      console.log("Skipping duplicate toggle call");
      return;
    }
    
    isUpdatingRef.current = true;
    
    setCauses(prevCauses => 
      prevCauses.map(cause => 
        cause.id === id ? { ...cause, selected: !cause.selected } : cause
      )
    );
    
    // Reset the flag after the state update is processed
    setTimeout(() => {
      isUpdatingRef.current = false;
    }, 0);
  };

  const handleSave = async () => {
    setSaving(true);
    setError(null);
    setSuccessMessage(null);
    
    try {
      const selectedCauseIds = causes
        .filter(cause => cause.selected)
        .map(cause => cause.id);
      
      await apiRequest('/causes', {
        method: 'POST',
        body: JSON.stringify(selectedCauseIds),
      });
      
      navigate('/');
      
    } catch (error) {
      console.error('Error updating causes:', error);
      setError('Failed to update causes. Please try again later.');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="Causes">
      <Header activePage="causes" showLogout={true} />
      
      <main className="main-content">
        <h2>Your Causes</h2>
        <p className="description">
          Select the causes you care about. We'll use this information to show you relevant content and opportunities to make a difference.
        </p>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        
        {successMessage && (
          <div className="success-message">
            {successMessage}
          </div>
        )}
        
        {loading ? (
          <div className="loading">Loading causes...</div>
        ) : (
          <>
            <div className="causes-list">
              {causes.length === 0 ? (
                <p className="no-causes">No causes available at the moment.</p>
              ) : (
                causes.map(cause => (
                  <div 
                    key={cause.id} 
                    className="cause-item"
                    onClick={() => handleCauseToggle(cause.id)}
                  >
                    <label className="cause-label" onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={cause.selected || false}
                        className="cause-checkbox"
                        onChange={(e) => {
                          e.stopPropagation();
                          handleCauseToggle(cause.id);
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <span 
                        className="cause-name"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCauseToggle(cause.id);
                        }}
                      >{cause.name}</span>
                      {cause.description && (
                        <div className="info-tooltip-container" onClick={(e) => e.stopPropagation()}>
                          <span className="info-icon">i</span>
                          <span className="tooltip-text">{cause.description}</span>
                        </div>
                      )}
                    </label>
                  </div>
                ))
              )}
            </div>
            
            <div className="actions">
              <button 
                className="save-button" 
                onClick={handleSave}
                disabled={saving}
              >
                {saving ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </>
        )}
      </main>
    </div>
  );
}

export default Causes;
