import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import { apiRequest } from './api';
import './Accounts.scss';
import './AccountGenerator.scss';

interface LocationState {
  platform?: string;
  platformName?: string;
}

interface AccountProfile {
  id?: string;
  profilePicUrl?: string;
  displayName: string;
  username: string;
  age?: number;
  gender?: string;
  location?: string;
  educationLevel?: string;
  communicationStyle?: string;
  publicBio?: string;
  privateBio?: string;
  strategy?: string;
}

function AccountGenerator() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState || {};
  const platformName = state.platformName || 'Social Media';
  const platform = state.platform || 'TWITTER';
  
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState<AccountProfile[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [personaType, setPersonaType] = useState<string>('');
  const [selectedProfile, setSelectedProfile] = useState<AccountProfile | null>(null);
  const [showUsernameInput, setShowUsernameInput] = useState(false);
  const [username, setUsername] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const fetchProfiles = async () => {
    try {
      setLoading(true);
      let url = '/profiles/generate?numProfiles=5';
      if (personaType) {
        url += `&personaType=${personaType}`;
      }
      const data = await apiRequest<AccountProfile[]>(url, {
        method: 'GET'
      });
      setProfiles(data);
      setError(null);
    } catch (err) {
      setError('Failed to load account profiles. Please try again.');
      console.error('Error fetching profiles:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, [state.platform, personaType]);

  const handlePersonaTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPersonaType(e.target.value);
  };

  const handleProfileClick = (profile: AccountProfile) => {
    setSelectedProfile(profile);
    // Pre-fill the username field with the suggested username
    setUsername(profile.username);
  };

  const handleCancel = () => {
    setSelectedProfile(null);
    setShowUsernameInput(false);
  };

  const handleDone = () => {
    setShowUsernameInput(true);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedProfile || !username) return;

    try {
      setSubmitting(true);
      await apiRequest('/accounts', {
        method: 'POST',
        body: JSON.stringify({
          platform,
          username,
          profileId: selectedProfile.id
        })
      });
      
      // Navigate back to accounts page or show success message
      navigate('/', { state: { success: true } });
    } catch (err) {
      setError('Failed to create account. Please try again.');
      console.error('Error creating account:', err);
      setSubmitting(false);
    }
  };

  const renderProfileList = () => {
    return (
      <>
        <div className="accounts-header">
          <h2>New {platformName} Account</h2>
          <div className="account-controls">
            <select 
              className="persona-type-dropdown" 
              value={personaType} 
              onChange={handlePersonaTypeChange}
            >
              <option value="">All Personas</option>
              <option value="PROGRESSIVE">Progressive</option>
              <option value="INFILTRATOR">Infiltrator</option>
              <option value="TROLL_DISRUPTOR">Troll Disruptor</option>
            </select>
            <button 
              className="refresh-button" 
              onClick={fetchProfiles} 
              disabled={loading}
              title="Refresh profiles"
            >
              ⟳
            </button>
          </div>
        </div>
        
        <div className="account-generator-container">
          {error && <div className="error-message">{error}</div>}
          
          {loading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <p>Loading account profiles...</p>
            </div>
          ) : (
            <div className="account-profiles-grid">
              {profiles.length > 0 ? (
                profiles.map((profile) => (
                  <div 
                    className="account-profile-card" 
                    key={profile.id}
                    onClick={() => handleProfileClick(profile)}
                  >
                    <div className="profile-top-section">
                      {profile.profilePicUrl && (
                        <div className="profile-pic-container">
                          <img 
                            src={profile.profilePicUrl} 
                            alt={`${profile.displayName}'s profile`} 
                            className="profile-pic" 
                          />
                        </div>
                      )}
                      <div className="profile-header-info">
                        <div className="profile-header">
                          <h3 className="display-name">{profile.displayName}</h3>
                          <p className="username">@{profile.username}</p>
                        </div>
                        <p className="demographics-location">
                          {[
                            profile.age && profile.gender && `${profile.age} • ${profile.gender}`,
                            profile.location
                          ].filter(Boolean).join(' • ')}
                        </p>
                        {profile.publicBio && (
                          <p className="public-bio">{profile.publicBio}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-profiles">No account profiles available. Try again later.</p>
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderProfileDetail = () => {
    if (!selectedProfile) return null;

    return (
      <>
        <div className="accounts-header">
          <h2>Create {platformName} Account</h2>
          <button 
            className="cancel-button" 
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
        
        <div className="account-generator-container">
          {error && <div className="error-message">{error}</div>}
          
          <div className="single-profile-container">
            <div className="account-profile-card">
              <div className="profile-top-section">
                {selectedProfile.profilePicUrl && (
                  <div 
                    className="profile-pic-container"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!selectedProfile.profilePicUrl) return;
                      
                      // Use an anchor element to download the image
                      const downloadImage = async (url: string, filename: string) => {
                        const response = await fetch(url, { mode: 'cors' });
                        const blob = await response.blob();
                        const blobUrl = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = blobUrl;
                        link.download = filename || "downloaded-image";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(blobUrl);
                      };

                      downloadImage(selectedProfile.profilePicUrl, `${selectedProfile.username}-profile.jpg`);
                    }}
                  >
                    <img 
                      src={selectedProfile.profilePicUrl} 
                      alt={`${selectedProfile.displayName}'s profile`} 
                      className="profile-pic" 
                    />
                    <div className="download-icon"></div>
                  </div>
                )}
                <div className="profile-header-info">
                  <div className="profile-header">
                    <h3 className="display-name">{selectedProfile.displayName}</h3>
                    <p className="username">@{selectedProfile.username}</p>
                  </div>
                  <p className="demographics-location">
                    {[
                      selectedProfile.age && selectedProfile.gender && `${selectedProfile.age} • ${selectedProfile.gender}`,
                      selectedProfile.location
                    ].filter(Boolean).join(' • ')}
                  </p>
                  {selectedProfile.publicBio && (
                    <p className="public-bio">{selectedProfile.publicBio}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="profile-instructions">
              <h3>Instructions</h3>
              <p>Please create a new {platformName} account:</p>
              <ol>
                <li>Go to {platformName} and sign up for a new account</li>
                <li>Use the suggested username (@{selectedProfile.username}) if available</li>
                <li>Fill in the profile information</li>
                <li>Upload the profile picture</li>
                <li>Set the bio</li>
                <li>When you're done, click the "I'm Done" button below</li>
              </ol>
            </div>

            {showUsernameInput ? (
              <form onSubmit={handleSubmit} className="username-form">
                <h3>What username did you use?</h3>
                <input
                  type="text"
                  value={username}
                  onChange={handleUsernameChange}
                  placeholder="Enter the username you created"
                  required
                />
                <div className="form-buttons">
                  <button 
                    type="button" 
                    onClick={() => setShowUsernameInput(false)}
                    className="secondary-button"
                  >
                    Back
                  </button>
                  <button 
                    type="submit" 
                    disabled={submitting || !username}
                    className="primary-button"
                  >
                    {submitting ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </form>
            ) : (
              <div className="profile-actions">
                <button 
                  className="done-button" 
                  onClick={handleDone}
                >
                  I'm Done
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="Accounts AccountGenerator">
      <Header activePage="accounts" showLogout={true} />
      
      <main className="main-content">
        {selectedProfile ? renderProfileDetail() : renderProfileList()}
      </main>
    </div>
  );
}

export default AccountGenerator;
