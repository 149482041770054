import React, { useEffect, useState } from 'react';
import { apiRequest } from './api';
import './Accounts.scss';
import Header from './components/Header';
import { useNavigate } from 'react-router-dom';

interface SocialPlatform {
  id: string;
  name: string;
}

interface SocialAccount {
  id: string;
  userId: string;
  platform: string;
  username: string;
}

function Accounts() {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<SocialAccount[]>([]);
  const [platforms, setPlatforms] = useState<SocialPlatform[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  
  // Account deletion state
  const [accountToDelete, setAccountToDelete] = useState<string | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Fetch accounts and platforms in parallel
      const [accountsData, platformsData] = await Promise.all([
        apiRequest<SocialAccount[]>('/accounts', { method: 'GET' }),
        apiRequest<SocialPlatform[]>('/platforms', { method: 'GET' })
      ]);
      
      setAccounts(accountsData);
      setPlatforms(platformsData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load accounts. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddAccount = () => {
    navigate('/accounts/add');
  };

  const confirmDeleteAccount = (accountId: string) => {
    setAccountToDelete(accountId);
  };

  const cancelDeleteAccount = () => {
    setAccountToDelete(null);
  };

  const handleDeleteAccount = async (accountId: string) => {
    setError(null);
    setSuccessMessage(null);
    
    try {
      await apiRequest(`/accounts/${accountId}`, {
        method: 'DELETE',
      });
      
      // Update local state by filtering out the deleted account
      setAccounts(prevAccounts => 
        prevAccounts.filter(account => account.id !== accountId)
      );
      
      setSuccessMessage('Account removed successfully!');
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      console.error('Error deleting account:', error);
      setError('Failed to remove account. Please try again later.');
    } finally {
      setAccountToDelete(null);
    }
  };

  return (
    <div className="Accounts">
      <Header activePage="accounts" showLogout={true} />
      
      <main className="main-content">
        <div className="accounts-header">
          <h2>Your Social Media Accounts</h2>
          <button onClick={handleAddAccount} className="open-modal-button">
            Add New Account
          </button>
        </div>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
        
        {successMessage && (
          <div className="success-message">
            {successMessage}
          </div>
        )}
        
        {loading ? (
          <div className="loading">Loading accounts...</div>
        ) : (
          <>
            
            {accountToDelete && (
              <div className="modal-overlay">
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                  <div className="modal-header">
                    <h3>Confirm Account Removal</h3>
                  </div>
                  
                  <div className="modal-body">
                    <p>Are you sure you want to remove this account?</p>
                    <p>This action cannot be undone.</p>
                  </div>
                  
                  <div className="modal-footer">
                    <button 
                      onClick={cancelDeleteAccount} 
                      className="cancel-button"
                    >
                      Cancel
                    </button>
                    <button 
                      onClick={() => handleDeleteAccount(accountToDelete)} 
                      className="delete-button"
                    >
                      Remove Account
                    </button>
                  </div>
                </div>
              </div>
            )}
            
            <div className="accounts-list-section">
              {accounts.length === 0 ? (
                <p className="no-accounts">No accounts connected yet.</p>
              ) : (
                <div className="accounts-list">
                  {accounts.map(account => {
                    const platform = platforms.find(p => p.id === account.platform);
                    return (
                      <div key={account.id} className="account-item">
                        <div className="account-info">
                          <span className="platform-name">{platform?.name || account.platform}</span>
                          <span className="username">@{account.username}</span>
                        </div>
                        <button
                          className="delete-button"
                          onClick={() => confirmDeleteAccount(account.id)}
                        >
                          Remove
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
}

export default Accounts;
