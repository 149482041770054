import React, { createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import PublicHomePage from './PublicHomePage';
import Dashboard from './Dashboard';
import Causes from './Causes';
import Accounts from './Accounts';
import AddAccount from './AddAccount';
import AccountGenerator from './AccountGenerator';
import { apiRequest, getToken } from './api';
import { useUserCauses } from './hooks/useUserCauses';
import { useUserAccounts } from './hooks/useUserAccounts';

interface User {
  id?: string;
  email?: string;
}

const UserContext = createContext<{
  user: User | null;
  loading: boolean;
}>({
  user: null,
  loading: true,
});

export const useUser = () => useContext(UserContext);

// Component to check if user has linked accounts and redirect if needed
const AccountsRedirectWrapper: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  const { hasLinkedAccounts, loading: accountsLoading } = useUserAccounts();
  const location = useLocation();
  
  if (location.pathname === '/accounts/add' || location.pathname === '/accounts/generate') {
    return <>{element}</>;
  }
  
  // If still loading accounts data, don't redirect yet
  if (accountsLoading) {
    return null;
  }
  
  // If user has no linked accounts, redirect to add accounts page
  if (hasLinkedAccounts === false) {
    return <Navigate to="/accounts/add" replace />;
  }
  
  // Otherwise, render the element
  return <>{element}</>;
};

// Component to check if user has selected causes and redirect if needed
const CausesRedirectWrapper: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  const { hasSelectedCauses, loading: causesLoading } = useUserCauses();
  const location = useLocation();
  
  // If we're already on the causes page, just render the element
  if (location.pathname === '/causes') {
    return <>{element}</>;
  }
  
  // If still loading causes data, don't redirect yet
  if (causesLoading) {
    return null;
  }
  
  // If user has no selected causes, redirect to causes page
  if (hasSelectedCauses === false) {
    return <Navigate to="/causes" replace />;
  }
  
  // If user has selected causes, check accounts next
  return <AccountsRedirectWrapper element={element} />;
};

const ProtectedRoute: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  const { user, loading } = useUser();
  
  if (loading) {
    return null;
  }
  
  if (!user || !user.id) {
    return <Navigate to="/" replace />;
  }
  
  return <CausesRedirectWrapper element={element} />;
};

const PublicRoute: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  const { user, loading } = useUser();
  
  if (loading) {
    return null;
  }
  
  if (user && user.id) {
    return <Navigate to="/dashboard" replace />;
  }
  
  return <>{element}</>;
};

function AppRouter() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const checkAuth = () => {
    return getToken();
  };

  const [tokenState, setTokenState] = useState<string | null>(checkAuth());

  useEffect(() => {
    const fetchUser = async () => {
      const token = getToken();
      
      if (!token) {
        setUser(null);
        setLoading(false);
        return;
      }
      
      try {
        const userData = await apiRequest<User>('/me', {
          method: 'GET',
        });
        
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();

    const handleAuthChange = () => {
      const newTokenState = checkAuth();
      
      if (newTokenState !== tokenState) {
        setTokenState(newTokenState);
      }
    };

    window.addEventListener('storage', handleAuthChange);
    window.addEventListener('auth-change', handleAuthChange);
    
    return () => {
      window.removeEventListener('storage', handleAuthChange);
      window.removeEventListener('auth-change', handleAuthChange);
    };
  }, [tokenState]);


  return (
    <UserContext.Provider value={{ user, loading }}>
      <BrowserRouter>
        <Routes>
          <Route 
            path="/" 
            element={<PublicRoute element={<PublicHomePage />} />} 
          />
          
          <Route 
            path="/dashboard" 
            element={<ProtectedRoute element={<Dashboard />} />} 
          />
          
          <Route 
            path="/causes" 
            element={<ProtectedRoute element={<Causes />} />} 
          />
          
          <Route 
            path="/accounts" 
            element={<ProtectedRoute element={<Accounts />} />} 
          />
          
          <Route 
            path="/accounts/add" 
            element={<ProtectedRoute element={<AddAccount />} />} 
          />
          
          <Route 
            path="/accounts/generate" 
            element={<ProtectedRoute element={<AccountGenerator />} />} 
          />
          
          <Route 
            path="*" 
            element={user && user.id ? <Navigate to="/dashboard" replace /> : <Navigate to="/" replace />} 
          />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default AppRouter;
