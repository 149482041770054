import { useState, useEffect } from 'react';
import { apiRequest } from '../api';

interface Cause {
  id: string;
  name: string;
  description: string;
  selected?: boolean;
}

export const useUserCauses = () => {
  const [causes, setCauses] = useState<Cause[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasSelectedCauses, setHasSelectedCauses] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchCauses = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const causesData = await apiRequest<Cause[]>('/causes', {
          method: 'GET',
        });
        
        setCauses(causesData);
        
        // Check if the user has selected any causes
        const selectedCauses = causesData.filter(cause => cause.selected);
        setHasSelectedCauses(selectedCauses.length > 0);
      } catch (error) {
        console.error('Error fetching causes:', error);
        setError('Failed to load causes');
        setHasSelectedCauses(null);
      } finally {
        setLoading(false);
      }
    };

    fetchCauses();
  }, []);

  return {
    causes,
    loading,
    error,
    hasSelectedCauses,
  };
};
